import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { AppBar, Toolbar, Button, Avatar, Typography, IconButton, Tooltip } from '@mui/material';
import UpvoteInfoModal from '../Popup/UpvoteInfoModal'; 
import { getUserDetails } from '../../api';
import HomeIcon from '@mui/icons-material/Home';
import UpvoteIcon from '@mui/icons-material/ThumbUp';
import './Header.css';

const Header = forwardRef((props, ref) => {
  const { currentUser, token, signOut } = useAuth();
  const navigate = useNavigate();
  const [remainingUpvotes, setRemainingUpvotes] = useState(0);
  const [showUpvoteInfo, setShowUpvoteInfo] = useState(false);

  // Function to fetch and update remaining upvotes
  const fetchRemainingUpvotes = useCallback(async () => {
    if (currentUser && token) {
      try {
        let cachedUser = localStorage.getItem('user');
        if (cachedUser){
          cachedUser=JSON.parse(cachedUser);
          cachedUser.username == currentUser.username ? setRemainingUpvotes(cachedUser.remainingUpvotes) : localStorage.removeItem('user'); ;
        }
        const response = await getUserDetails(token);
        setRemainingUpvotes(response.remainingUpvotes);
        localStorage.setItem('user',JSON.stringify(response));
      } catch (error) {
        console.error("Error fetching remaining upvotes:", error);
      }
    }
  }, [currentUser, token]);

  const isUpvotesRemaining = useCallback( () => {
    return remainingUpvotes > 0;
  })
  useEffect(() => {
    fetchRemainingUpvotes(); // Fetch remaining upvotes on initial load
  }, [fetchRemainingUpvotes]);

   // Expose the fetchRemainingUpvotes method to the parent component using ref
   useImperativeHandle(ref, () => ({
    fetchRemainingUpvotes,
    isUpvotesRemaining
  }));

  const handleUpvoteInfoClick = () => {
    setShowUpvoteInfo(true);
  };

  const handleSignOut = () => {
    signOut();
    navigate('/Login');
  };

  return (
    <AppBar 
      position="sticky"
      className="header" 
      sx={{ backgroundColor: '#000', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }} // Added black background
    >
      <Toolbar  sx={{padding:'0px'}} className="header-toolbar">
        {/* Home Button */}
        <Button sx={{padding:'0px'}} color="inherit" onClick={() => navigate('/home')}>
          <HomeIcon />
        </Button>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Scribble Sanctuary
        </Typography>

        {/* Right Section of the Header */}
        <div className="header-right">
          {currentUser && (
            <>
              <Tooltip title={`${remainingUpvotes} Upvotes Left`}>
                <IconButton onClick={handleUpvoteInfoClick} color="inherit">
                  <UpvoteIcon sx={{paddingRight:'2px'}}/>
                  <span>{remainingUpvotes}</span>
                </IconButton>
              </Tooltip>
              {/* Avatar to Navigate to Profile */}
              <Avatar
                className="profile-avatar"
                onClick={() => navigate(`/profile/${currentUser.username}`)}
                sx={{ cursor: 'pointer', backgroundColor: '#ff6b6b' }} // Customized avatar styles
              >
                {currentUser.username[0].toUpperCase()}
              </Avatar>

              {/* Sign Out Button */}
              <Button color="inherit" onClick={handleSignOut}>
                Sign Out
              </Button>
            </>
          )}
        </div>
      </Toolbar>
      <UpvoteInfoModal open={showUpvoteInfo} onClose={() => setShowUpvoteInfo(false)} />
    </AppBar>
  );
});

export default Header;
