import React, { useState } from 'react';
import Login from '../components/Auth/Login';
import SignUp from '../components/Auth/Signup';
import './LoginPage.css'; // Create a CSS file to style the page

const LoginPage = () => {
  const [showLogin, setShowLogin] = useState(true); // State to toggle between login and signup

  return (
    <div className="login-page-container">
      <div className="login-card">
        <h1>Welcome to Scribble Sanctuary</h1>
        {/* Toggle Buttons */}
        <div className="toggle-buttons">
          <button
            className={`toggle-btn ${showLogin ? 'active' : ''}`}
            onClick={() => setShowLogin(true)}
          >
            Login
          </button>
          <button
            className={`toggle-btn ${!showLogin ? 'active' : ''}`}
            onClick={() => setShowLogin(false)}
          >
            Sign Up
          </button>
        </div>
        {/* Show the appropriate component based on the state */}
        {showLogin ? <Login /> : <SignUp />}
      </div>
    </div>
  );
};

export default LoginPage;
