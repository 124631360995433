import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography } from '@mui/material';
import { useAuth } from '../../AuthContext'
import LoadingBanner from '../Layout/LoadingBanner';
import './Login.css';

const SignUp = () => {
  const navigate = useNavigate();
  const {register, signIn ,globalLoading, setGlobalLoading} = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSignUp = async () => {
    try {
      setGlobalLoading(true);
      const response = await register(username, password);
      console.log(response);
      setGlobalLoading(false);
      if (response.message === 'User created successfully') {
        setSuccess('Signup successful! Trying to Log you in...');
        const result = await signIn(username, password);
        if (result) {
          setSuccess('Login successful! Redirecting...');
          setGlobalLoading(false);
          setTimeout(() => {
            navigate('/home');
          }, 1000);
        }
        else {
          setError('Login failed. Please Try to login');
          setGlobalLoading(false);
        }
      } else {
        setError('Signup failed.' + response.message);
      }
    } catch (error) {
      setError('An error occurred during signup.' + error);
    }
  };

  return (
    <div className="signup-page">
      <h2>Sign Up</h2>
      {error && <Typography className="error-message">{error}</Typography>}
      {success && <Typography className="success-message">{success}</Typography>}
      <LoadingBanner loading={globalLoading} message="Signing you up!" />
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="input-field"
        variant="outlined"
        sx={{margin:'5px'}}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="input-field"
        variant="outlined"
        sx={{margin:'5px'}}
        onKeyDown={(e) => {e.code == 13 ? handleSignUp(): e.isDefaultPrevented()}}
      />
      <Button variant="contained" sx={{background:'#333', marginTop:'20px', padding:'10px 20px'}} onClick={handleSignUp}>
        Sign Up
      </Button>
    </div>
  );
};

export default SignUp;
