import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const UpvoteInfoModal = ({ open, onClose }) => {
    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{ padding: 3, backgroundColor: 'white', borderRadius: '8px', margin: 'auto', maxWidth: 400, textAlign: 'center' }}>
          <Typography variant="h6">Upvote Limit Information</Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            You have 20 upvotes per day. This limit resets daily at 00:00 UTC.
          </Typography>
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            You can upvote any thought you like for as many times you want. So show your love to your favorite thoughts. The number of upvotes is limited to ensure thinkers can be mindful about consuming and promoting content they like.
            You can use your remaining upvotes before the daily reset.
          </Typography>
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            Though the votes are limited to 20, buy me a coffee to help host the free app and get some extra benifits while staying true to our mindful upvotes moto!
          </Typography>
          <Button onClick={onClose} sx={{ marginTop: 3 }} variant="contained">Close</Button>
        </Box>
      </Modal>
    );
  };
  
  export default UpvoteInfoModal;