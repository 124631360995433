import React, { useState, useEffect } from 'react';
import { Button, TextField, Checkbox, FormControlLabel } from '@mui/material';
import './ThoughtEditor.css'; // Import custom styles

const ThoughtEditor = ({ onClose, onCreateThought, existingThought }) => {
  const [text, setText] = useState(existingThought ? existingThought.text : '');
  const [isPrivate, setIsPrivate] = useState(existingThought ? existingThought.isPrivate : false);

  // Add enter animation when the component is mounted
  useEffect(() => {
    const editor = document.querySelector('.thought-editor-container');
    if (editor) {
      editor.classList.add('show');
    }
  }, []);

  const handleSubmit = () => {
    const newThought = { text, isPrivate };
    onCreateThought(newThought);
    setText('');
    setIsPrivate(false);
    onClose(); // Close the editor after submission
  };

  return (
    <div className="thought-editor-container">
      <div className="thought-editor-content">
        <h3>{existingThought ? 'Edit Thought' : 'Create New Thought'}</h3>
        <TextField
          label="What's on your mind?"
          multiline
          rows={4}
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          className="editor-input"
        />
        <FormControlLabel
          control={<Checkbox checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} />}
          label="Private"
        />
        <div className="editor-actions">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {existingThought ? 'Update Thought' : 'Create Thought'}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThoughtEditor;
