const BASE_URL = "https://scribbile-sanctuary.azurewebsites.net/api";

// Sign-Up API Call
export async function signUp(username, password) {
  const response = await fetch(`${BASE_URL}/SignUp`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  });
  return response.json();
}

// Login API Call
export async function login(username, password) {
  const response = await fetch(`${BASE_URL}/Login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  });
  return response.json();
}

// Create Thought API Call
export async function createThought(newThought, token) {
  const response = await fetch(`${BASE_URL}/CreateThought`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newThought),
  });
  return response.json();
}

// Get All Thoughts API Call
export async function getAllThoughts(token, orderBy) {
  const response = await fetch(`${BASE_URL}/GetAllThoughts?orderby=${orderBy}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
}

// Get Thoughts by User
export async function getThoughtsByUser(username, token, orderBy) {
  const response = await fetch(`${BASE_URL}/GetThoughtsByUser?username=${username}&orderby=${orderBy}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
}

// Update Thought
export async function updateThought(updatedThought, token) {
  const response = await fetch(`${BASE_URL}/UpdateThought`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(updatedThought),
  });
  return response.json();
}

// Delete Thought
export async function deleteThought(id, token) {
  const response = await fetch(`${BASE_URL}/DeleteThought?id=${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
}

export async function upvoteThought(thoughtId, username, upvotes, partitionKey, token) {
  const response = await fetch(`${BASE_URL}/UpvoteThought`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ thoughtId, username, upvotes, partitionKey  }),
  });
  return response.json();
}

export async function getUserDetails(token) {
  const response = await fetch(`${BASE_URL}/GetUserDetails`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
}
