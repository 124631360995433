import React, { useState } from 'react';
import ThoughtCard from './ThoughtCard';
import './ThoughtList.css';

const ThoughtList = ({ thoughts, currentUser, onEdit, onDelete, upvoteRef, sortChange}) => {
  const [filter, setFilter] = useState('all');
  const [orderBy, setOrderBy] = useState(0);

  const handleSortChange = (event) => {
    setOrderBy(parseInt(event.target.value, 10)); // Update the sorting option
    sortChange(parseInt(event.target.value, 10));
  };

  const filteredThoughts = thoughts?.length > 0 ? thoughts.filter((thought) => {
    if (filter === 'all') return true;
    if (filter === 'private') return thought.isPrivate;
    if (filter === 'public') return !thought.isPrivate;
    return true;
  }) : thoughts;

  return (
    <div className="thought-list-container">
      {/* Filter Bar with Updated Styles */}
      <div className="filter-bar">
        <button
          className={`filter-btn ${filter === 'all' ? 'active' : ''}`}
          onClick={() => setFilter('all')}
        >
          All
        </button>
        <button
          className={`filter-btn ${filter === 'public' ? 'active' : ''}`}
          onClick={() => setFilter('public')}
          disabled={thoughts.length === 0}
        >
          Public
        </button>
        <button
          className={`filter-btn ${filter === 'private' ? 'active' : ''}`}
          onClick={() => setFilter('private')}
          disabled={thoughts.length === 0}
        >
          Private
        </button>
        {/* Sorting Dropdown */}
        <div className="sorting-container">
          <label htmlFor="sort">Sort by: </label>
          <select id="sort" value={orderBy} onChange={handleSortChange}>
            <option value="0">Latest Thoughts</option>
            <option value="1">Oldest Thoughts</option>
            <option value="2">Most Popular</option>
            <option value="3">Most Popular (Last Day)</option>
            <option value="4">Most Popular (Last Week)</option>
            <option value="5">Most Popular (Last Month)</option>
          </select>
        </div>
      </div>

      <div className="thought-list">
        {filteredThoughts.length === 0 ? (
          <p className="empty-message">No thoughts to display.</p>
        ) : (
          filteredThoughts.map((thought) => (
            <ThoughtCard
              key={thought.id}
              thought={thought}
              currentUser={currentUser}
              onEdit={onEdit}
              onDelete={onDelete}
              upvoteRef={upvoteRef}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ThoughtList;
