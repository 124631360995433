import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode'; // You'll need to install this package (npm install jwt-decode)
import { login, signUp } from './api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [globalLoading, setGlobalLoading] = useState(true); // New state for global loading banner

  useEffect(() => {
    const savedToken = localStorage.getItem('authToken');
    const savedExpiry = localStorage.getItem('tokenExpiry');

    if (savedToken && savedExpiry) {
      const now = Date.now();
      if (now < parseInt(savedExpiry, 10)) {
        setToken(savedToken);
        const decoded = jwtDecode(savedToken);
        setCurrentUser({ username: decoded.username });
      } else {
        // Token expired, clear localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('username');
        localStorage.removeItem('tokenExpiry');
      }
    }
    setGlobalLoading(false); // Stop showing global loading after token check
  }, []);

  const signIn = async (username, password) => {
    try {
      setGlobalLoading(true);
      const response = await login(username, password);
      if (response.token) {
        const decoded = jwtDecode(response.token);
        const expiryTime = decoded.exp * 1000; // JWT 'exp' is in seconds, convert to milliseconds

        setToken(response.token);
        setCurrentUser({ username: decoded.username });

        // Store token and expiry time in localStorage
        localStorage.setItem('authToken', response.token);
        localStorage.setItem('username', decoded.username);
        localStorage.setItem('tokenExpiry', expiryTime.toString()); // Store the expiry time as a string

        return true;
      } else {
        return false;
      }
    } finally {
      setGlobalLoading(false);
    }
  };

  const register = async (username, password) => {
    try {
      setGlobalLoading(true);
      const response = await signUp(username, password);
      return response;
    } finally {
      setGlobalLoading(false);
    }
  };

  const signOut = () => {
    setCurrentUser(null);
    setToken(null);
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('thoughts');
    localStorage.removeItem('username');
    localStorage.removeItem('tokenExpiry'); // Remove the expiry time when signing out
  };

  return (
    <AuthContext.Provider value={{ currentUser, token, signIn, signOut, register, globalLoading, setGlobalLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
