import React from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './pages/LoginPage';
import { useAuth } from '././AuthContext';
import Header from './components/Layout/Header';
import LandingPage from './pages/LandingPage';

function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/" />;
}

function App() {
  const { currentUser } = useAuth();
  const headerRef = React.useRef(null);
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" 
          element={currentUser ? <>
                  <Header ref={headerRef}/> 
                  <HomePage upvoteRef={headerRef?.current}/>
                </>: <LandingPage />} />
          <Route path="/Login" element={<><Header /> <LoginPage /> </>} />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Header ref={headerRef}/> 
                <HomePage upvoteRef={headerRef?.current}/>
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/:username"
            element={
              <PrivateRoute>
                <Header /> 
                <ProfilePage upvoteRef={headerRef?.current}/>
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
