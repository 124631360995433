import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography } from '@mui/material';
import { useAuth } from '../../AuthContext';
import LoadingBanner from '../Layout/LoadingBanner';
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const { signIn, globalLoading, setGlobalLoading, currentUser, token} = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if(token && currentUser)
        navigate('/home')
  }, )

  const handleLogin = async () => {
    setGlobalLoading(true);
    const result = await signIn(username, password);
    if (result) {
      setSuccess('Login successful! Redirecting...');
      setGlobalLoading(false);
      setTimeout(() => {
        navigate('/home');
      }, 1500);
    } else {
      setError('Login failed. Please check your credentials.');
      setGlobalLoading(false);
    }
  };

  return (
    <div className="login-page">
      <h2>Login</h2>
      {error && <Typography className="error-message">{error}</Typography>}
      {success && <Typography className="success-message">{success}</Typography>}
      <LoadingBanner loading={globalLoading} message="Logging you in..." />
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="input-field"
        variant="outlined"
        sx={{margin:'5px'}}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="input-field"
        variant="outlined"
        sx={{margin:'5px'}}
        onKeyDown={(e) => {e.code === 13 ? handleLogin(): e.isDefaultPrevented()}}
      />
      <Button sx={{background:'#333', marginTop:'20px', padding:'10px 20px'}} variant="contained"  onClick={handleLogin}>
        Login
      </Button>
    </div>
  );
};

export default Login;
