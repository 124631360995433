import React from 'react';
import { CircularProgress } from '@mui/material';
import './LoadingBanner.css';

const LoadingBanner = ({ loading, message }) => {
  if (!loading) return null;

  return (
    <div className="loading-banner">
      <CircularProgress className="spinner" />
      <span className="loading-message">{message || 'Loading...'}</span>
    </div>
  );
};

export default LoadingBanner;
