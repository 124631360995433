import React, { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import './ConfirmDialog.css';

const ConfirmDialog = ({ open, onClose, onConfirm, title, message }) => {
  useEffect(() => {
    const dialog = document.querySelector('.dialog-content');
    if (dialog && open) {
      dialog.classList.add('fade-in');
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="dialog-content">
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={onConfirm} color="primary">Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
