import React, { useState, useEffect } from 'react';
import { getAllThoughts, updateThought, createThought, deleteThought } from '../api';
import ThoughtList from '../components/Thought/ThoughtList';
import FloatingButton from '../components/Popup/FloatingButton';
import ThoughtEditor from '../components/Thought/ThoughtEditor';
import ConfirmDialog from '../components/Popup/ConfirmDialog';
import { useAuth } from '../AuthContext';
import LoadingBanner from '../components/Layout/LoadingBanner';
import './HomePage.css';

const HomePage = ({upvoteRef}) => {
  const { token, currentUser, globalLoading, setGlobalLoading } = useAuth();
  const [thoughts, setThoughts] = useState([]);
  const [editThought, setEditThought] = useState(null); // Track the thought being edited
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // New success message state
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [thoughtToDelete, setThoughtToDelete] = useState(null);
  const [orderBy, setOrderBy] = useState(0);
  const [initialLoad, setInitalLoad] = useState(true);

  // Function to fetch thoughts from the server
  const fetchData = async (skipCache = false) => {
    setGlobalLoading(true);
    if(!skipCache){
      const cachedThoughts = localStorage.getItem('thoughts');
      if (cachedThoughts) {
        setThoughts(JSON.parse(cachedThoughts));
        setGlobalLoading(false);
      }
    }

    const response = await getAllThoughts(token, orderBy);
    setGlobalLoading(true);
    setThoughts(response);
    localStorage.setItem('thoughts', JSON.stringify(response));
    setGlobalLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchData(!initialLoad);
      setInitalLoad(false);// Fetch thoughts on component mount
    }
  }, [token, orderBy]);

  // Listen for visibility changes (tab switching)
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchData(true); // Skip cache when returning to the tab
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [orderBy]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        fetchData(true); // Skip cache when refreshing from scroll
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [orderBy]);

  // Pull-to-refresh for mobile
  useEffect(() => {
    let touchStartY = 0;
    let touchEndY = 0;

    const handleTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
      touchEndY = e.changedTouches[0].clientY;
      if (touchEndY - touchStartY > 50 && window.scrollY === 0) {
        fetchData(true); // Skip cache when pulling down to refresh
      }
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [orderBy]);



  // Define the handleEdit function to set the thought being edited
  const handleEdit = (thought) => {
    setEditThought(thought);
    setIsEditing(true);
  };

  // Handle thought creation and updating
  const handleCreateOrUpdateThought = async (newThought) => {
    setGlobalLoading(true);
    if (isEditing && editThought) {
      await updateThought({ ...editThought, ...newThought }, token);
      setSuccessMessage('Thought updated successfully!');
    } else {
      await createThought(newThought, token);
      setSuccessMessage('Thought created successfully!');
    }
    setIsEditing(false);
    setEditThought(null);

    // Fetch updated thoughts from server
    await fetchData();

    setGlobalLoading(false);

    // Remove the success message after 3 seconds
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000);
  };

  const handleDelete = (id) => {
    setThoughtToDelete(id);
    setConfirmOpen(true);
  };

  const confirmDelete = async () => {
    setGlobalLoading(true);
    await deleteThought(thoughtToDelete, token);
    setSuccessMessage('Thought deleted successfully!');

    // Fetch updated thoughts from server
    await fetchData();

    setGlobalLoading(false);
    setConfirmOpen(false);

    // Remove the success message after 3 seconds
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000);
  };

  return (
    <div className="homepage">
      <LoadingBanner loading={globalLoading} message="Loading... Please wait..." />
      <h2>All Thoughts</h2>

      {/* Success Message Display */}
      {successMessage && <div className="success-message">{successMessage}</div>}

      {/* Thought List */}
      <ThoughtList
        thoughts={thoughts}
        onEdit={handleEdit}
        onDelete={handleDelete}
        currentUser={currentUser.username}
        upvoteRef= {upvoteRef}
        sortChange={setOrderBy}
      />

      {/* Floating Button for Thought Creation */}
      <FloatingButton onCreateThought={handleCreateOrUpdateThought} />

      {/* Thought Editor Popup */}
      {isEditing && (
        <ThoughtEditor
          existingThought={editThought}
          onClose={() => setIsEditing(false)}
          onCreateThought={handleCreateOrUpdateThought}
        />
      )}

      {/* Confirm Deletion Dialog */}
      <ConfirmDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this thought? This action cannot be undone."
      />
    </div>
  );
};

export default HomePage;
