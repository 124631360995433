import React from 'react';
import { Button, Avatar } from '@mui/material';
import './ProfileHeader.css'; // Custom styles for the profile header

const ProfileHeader = ({ username, isCurrentUser }) => {
  return (
    <div className="profile-header">
      <div className="profile-avatar-container">
        <Avatar className="profile-head-avatar" sx={{ width: 100, height: 100 }}>
          {username[0].toUpperCase()}
        </Avatar>
        <h2 className="profile-username">{username}</h2>
        {!isCurrentUser && (
          <Button variant="contained" className="follow-button">
            Follow
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
