import React, { useState } from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ThoughtEditor from '../Thought/ThoughtEditor';
import './FloatingButton.css';

const FloatingButton = ({ onCreateThought }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Fab sx={{position:'fixed'}} className="floating-btn" onClick={handleOpen} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
      {open && (
        <div className="thought-popup">
          <div className="popup-content">
            <ThoughtEditor onClose={handleClose} onCreateThought={onCreateThought} />
          </div>
        </div>
      )}
    </>
  );
};

export default FloatingButton;
