import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip, Button, ButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UpvoteIcon from '@mui/icons-material/ThumbUp';
import CircularProgress from '@mui/material/CircularProgress';
import { json, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import {upvoteThought} from '../../api'
import './ThoughtCard.css';

const ThoughtCard = ({ thought, currentUser, onEdit, onDelete, upvoteRef}) => {
  const [upvotes, setUpvotes] = useState(thought.upvotes? thought.upvotes : 0);
  const [upvoteLoading, setUpvoteLoading] = useState(false);
  const [userUpvotes, setUserUpvotes] = useState( thought?.upvotedBy?.find(u => u.username === currentUser)?.upvotes ? thought.upvotedBy.find(u => u.username === currentUser).upvotes : 0);
  const {token} = useAuth();
  const navigate = useNavigate();


  // Function to handle upvoting
  const handleUpvote = async (change) => {
    try {
      setUpvoteLoading(true);
      const response = await upvoteThought(thought.id, currentUser, change,thought.user, token);
      if (response.upvotes >= 0) {
        // Update upvotes count based on server response
        setUpvotes(response.upvotes);
        setUserUpvotes(userUpvotes + change); // Update user's upvotes for this thought
        //update Local cache -> \
        setTimeout(() => {
          const cachedThoughts = JSON.parse(localStorage.getItem('thoughts'));
          if (cachedThoughts) {
            let UpdatedThoughts = cachedThoughts.map((x) => {
              if (x.id === response.id) {
                return response;
              }
              return x; // Keep other thoughts unchanged
            });
            localStorage.setItem('thoughts', JSON.stringify(UpdatedThoughts));
          }
          
          const user = JSON.parse(localStorage.getItem('user'));
          if(user && user.remainingUpvotes >=0){
            user.remainingUpvotes -= change;
            localStorage.setItem('user', JSON.stringify(user));
          }
          if (upvoteRef)  upvoteRef.fetchRemainingUpvotes();
        }, 0);
      }
    } catch (error) {
      console.error("Error upvoting thought:", error);
    } finally {
      setUpvoteLoading(false);
    }
  };

  // Disable upvoting for own thoughts
  const isOwnThought = thought.user === currentUser;

  const handleProfileClick = () => {
    navigate(`/profile/${thought.user}`);
  };

  return (
    <Card style={{ margin: '6px 8px', padding: 14}} className={`thought-card ${thought.isPrivate ? 'private-card' : ''}`}>
      <CardContent>
        <div className="thought-header">
          <Typography variant="h6" className="thought-username" onClick={handleProfileClick}>
            {thought.user}
          </Typography>
          <Typography variant="caption" className="timestamp">
            {new Date(thought.timestamp).toLocaleString()}
          </Typography>
        </div>
        <Typography variant="body1" className="thought-text">
        {thought.text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
        </Typography>
       
       <div className='action-bar'>
        <div className="upvote-actions">
          <Tooltip title="Total Upvotes">
            <div>
              <Button disabled id='tpbutton' >
              {upvoteLoading? <CircularProgress size="24px" sx={{paddingRight:'4px'}} /> : <UpvoteIcon sx={{paddingRight:'4px'}}/>} {upvotes}
              </Button>
            </div>
            </Tooltip>
            {!isOwnThought && <ButtonGroup variant="plain" size="sm">
              <Tooltip title="Add upvote">
              <Button disabled={upvoteLoading || !upvoteRef?.isUpvotesRemaining() } onClick={()=>handleUpvote(1)} sx={{color:'#888', padding:'8px'}} >
                <ArrowUpwardIcon/>
              </Button>
              </Tooltip>
              <Button  disabled id='tpbutton1' sx={{color:'#888',padding:'8px'}} >
                {userUpvotes}
              </Button>
              <Tooltip title="Remove upvote">
              <Button disabled={upvoteLoading || userUpvotes <= 0 ? true : false} onClick={()=>handleUpvote(-1)} sx={{color:'#888', padding:'8px'}} >
                <ArrowDownwardIcon/>
              </Button>
              </Tooltip>
          </ButtonGroup> }
        </div>
          <div className="thought-actions">
            {thought.isPrivate && (
            <Tooltip title="This thought is private">
                <LockIcon className="private-icon" />
            </Tooltip>
            )}
             {isOwnThought && (
            <><Tooltip title="Edit">
            <IconButton className="edit-btn" onClick={() => onEdit(thought)}>
                <EditIcon />
            </IconButton>
            </Tooltip><Tooltip title="Delete">
                <IconButton className="delete-btn" onClick={() => onDelete(thought.id)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip></>
            )} 
          </div>
        </div>

      </CardContent>
    </Card>
  );
};

export default ThoughtCard;
