import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css'; // CSS for landing page styling

const LandingPage = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login'); // Redirect to the login page
  };

  return (
    <div className="landing-container">
      <section className="landing-header">
        <h1 className="landing-title">Scribble Sanctuary</h1>
        <p className="landing-subtitle">A place where your thoughts flow freely, anonymously, and without judgment.</p>
        <Button variant="contained" color="primary" className="login-button" onClick={handleLoginRedirect}>
          Get Started
        </Button>
      </section>

      <section className="landing-content">
        <h2>What Makes Scribble Sanctuary Different?</h2>
        <p>Scribble Sanctuary is a unique platform where users can jot down their deepest thoughts, wildest ideas, or simple musings without revealing their identity. Whether it’s something personal or a fleeting idea, Scribble Sanctuary provides a safe space to share.</p>

        <h3>Anonymity</h3>
        <p>No profiles, no tracking, no judgment. Share your thoughts without the pressure of likes or comments tied to your name.</p>

        <h3>Private Thoughts</h3>
        <p>Want to keep something just for yourself? Mark any thought as private, and only you will be able to see it. It's like a personal diary, but with the flexibility to share only what you want.</p>

        <h3>Global Homepage</h3>
        <p>All public thoughts from users around the world are displayed on the global homepage. It's an ever-changing feed of anonymous voices, full of random insights, feelings, and ideas. Here, you can:</p>
        <ul>
          <li>Filter by Location: Discover what people are thinking in your city or across the world.</li>
          <li>Sort by Popularity: See which thoughts are resonating with others by sorting based on upvotes.</li>
          <li>Sort by Activity: Explore the most recently active or commented thoughts in real-time.</li>
        </ul>

        <h3>Upvotes, No Downvotes</h3>
        <p>Like what someone has shared? Show your support by upvoting a thought. There’s no option for downvoting—keeping the platform positive and constructive.</p>

        <h2>Why Use Scribble Sanctuary?</h2>
        <h3>Share Without Fear</h3>
        <p>In a world full of opinions, it can be hard to speak freely. Scribble Sanctuary gives you the opportunity to share what’s on your mind without fear of judgment or retaliation. It’s just your thoughts, plain and simple.</p>

        <h3>A New Perspective</h3>
        <p>Explore a mosaic of anonymous thoughts from people you don’t know, but might just relate to. Scribble Sanctuary fosters empathy and understanding by sharing raw, unfiltered voices from all walks of life.</p>

        <h3>Personal Diary Meets Public Forum</h3>
        <p>Use Scribble Sanctuary as your private journaling tool, or contribute to the public dialogue. You decide how much or how little you share.</p>

        <h3>Pure Expression, No Pressure</h3>
        <p>With no public profiles, no followers, and no popularity contests, there’s no pressure to perform. Just scribble your thoughts, release them into the universe, and let go.</p>

        <h2>Use Cases for Scribble Sanctuary</h2>
        <ul>
          <li><strong>Mental Well-being:</strong> Share thoughts you’re unsure of or use it as a space to clear your mind. Whether it's stress, anxiety, or simply brainstorming, Scribble Sanctuary offers a cathartic space for release.</li>
          <li><strong>Public Pulse:</strong> See what’s on the mind of others in your city or around the world. It’s like hearing a global conversation, but one without names or faces—just thoughts.</li>
          <li><strong>Idea Generation:</strong> Need inspiration for a project or concept? Scribble Sanctuary’s feed of anonymous ideas and thoughts might spark your next big breakthrough.</li>
          <li><strong>Random Musings:</strong> Sometimes we have fleeting thoughts that don’t fit anywhere. Scribble Sanctuary is a perfect outlet for those one-off ideas, random questions, or quirky musings.</li>
          <li><strong>Connection Without Identity:</strong> Feel connected to strangers in an intimate way, without knowing who they are. It’s pure thought-sharing, where the content matters more than the person behind it.</li>
        </ul>

        <h2>How to Get Started</h2>
        <ol>
          <li><strong>Scribble Your Thoughts:</strong> Once you sign up, you can start scribbling right away. Jot down whatever's on your mind.</li>
          <li><strong>Choose Privacy:</strong> Keep it private, or share it with the world—the choice is yours.</li>
          <li><strong>Explore the Global Homepage:</strong> See what others have scribbled and upvote the thoughts that resonate with you.</li>
        </ol>

        <h2>Scribble Sanctuary: Your Thoughts, Your Space, Your Way</h2>
        <p>Ready to let your thoughts flow? Join us today!</p>
      </section>
    </div>
  );
};

export default LandingPage;
