import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getThoughtsByUser, updateThought, createThought, deleteThought } from '../api';
import { useAuth } from '../AuthContext';
import ProfileHeader from '../components/Profile/ProfileHeader';
import ThoughtList from '../components/Thought/ThoughtList';
import FloatingButton from '../components/Popup/FloatingButton';
import ThoughtEditor from '../components/Thought/ThoughtEditor';
import LoadingBanner from '../components/Layout/LoadingBanner';
import ConfirmDialog from '../components/Popup/ConfirmDialog';
import './ProfilePage.css';

const ProfilePage = ({upvoteRef}) => {
  const { username } = useParams();
  const { currentUser, token, globalLoading, setGlobalLoading } = useAuth();
  const [thoughts, setThoughts] = useState([]);
  const [showPrivate, setShowPrivate] = useState(true); // Toggle for private thoughts
  const [editThought, setEditThought] = useState(null); // Track the thought being edited
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // State for success messages
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [thoughtToDelete, setThoughtToDelete] = useState(null);
  const [orderBy, setOrderBy] = useState(0);
  const isCurrentUser = currentUser && currentUser.username === username;

  // Function to fetch user's thoughts from the server
  const fetchUserThoughts = async () => {
    setGlobalLoading(true);
    const data = await getThoughtsByUser(username, token, orderBy);
    setThoughts(data);
    setGlobalLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchUserThoughts();
    }
  }, [token, username, orderBy]);

  // Handle thought creation and updating
  const handleCreateOrUpdateThought = async (newThought) => {
    setGlobalLoading(true);
    if (isEditing && editThought) {
      await updateThought({ ...editThought, ...newThought }, token);
      setSuccessMessage('Thought updated successfully!');
    } else {
      await createThought(newThought, token);
      setSuccessMessage('Thought created successfully!');
    }
    setIsEditing(false);
    setEditThought(null);

    // Fetch updated thoughts from the server
    await fetchUserThoughts();

    setGlobalLoading(false);

    // Remove the success message after 3 seconds
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000);
  };

  const handleEdit = (thought) => {
    setEditThought(thought);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    setThoughtToDelete(id);
    setConfirmOpen(true);
  };

  const confirmDelete = async () => {
    setGlobalLoading(true);
    await deleteThought(thoughtToDelete, token);
    setSuccessMessage('Thought deleted successfully!');

    // Fetch updated thoughts from the server
    await fetchUserThoughts();

    setGlobalLoading(false);
    setConfirmOpen(false);

    // Remove the success message after 3 seconds
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000);
  };

  return (
    <div className="profile-page">
      <ProfileHeader username={username} isCurrentUser={isCurrentUser} />
      <LoadingBanner loading={globalLoading} message="Loading... Please wait..." />
      
      {/* Success Message Display */}
      {successMessage && <div className="success-message">{successMessage}</div>}

      {isCurrentUser && (
        <div className="filter-toggle">
          <span>Show Private Thoughts</span>
          <input
            type="checkbox"
            checked={showPrivate}
            onChange={(e) => setShowPrivate(e.target.checked)}
          />
        </div>
      )}

      {/* Thought List with Filtered Thoughts */}
      <ThoughtList
        thoughts={isCurrentUser ? thoughts.filter((t) => showPrivate || !t.isPrivate) : thoughts}
        onEdit={isCurrentUser ? handleEdit : null}
        onDelete={isCurrentUser ? handleDelete : null}
        currentUser={currentUser.username}
        upvoteRef={upvoteRef}
        sortChange={setOrderBy}
      />

      {/* Floating Button for Creating New Thoughts */}
      {isCurrentUser && <FloatingButton onCreateThought={handleCreateOrUpdateThought} />}

      {/* Thought Editor Popup for Creating/Editing Thoughts */}
      {isEditing && (
        <ThoughtEditor
          existingThought={editThought}
          onClose={() => setIsEditing(false)}
          onCreateThought={handleCreateOrUpdateThought}
        />
      )}

      {/* Confirm Deletion Dialog */}
      <ConfirmDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this thought? This action cannot be undone."
      />
    </div>
  );
};

export default ProfilePage;
